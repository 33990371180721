body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
}

body {
    margin-bottom: 5em;
}


.header {
    background-color: #B70C34;
    position: sticky;
    width: 100%;
    height: 80px;
    margin-bottom: 50px;
}

.header img {
    width: 60px;
    margin-right: 10px;
}

.header .wrapper {
    display: flex;
    max-width: 500px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: auto;
}

.header h1 {
    color: white;
    font-size: 1.4em;
    font-weight: 500;
}

h3 {
    text-align: center;
}

.logout {
    display: block;
    line-height: 1em;
    background-color: #B70C34;;
    border-radius: 4px;
    border: 1px solid #ffffff;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial, sans-serif;
    font-size: 15px;
    padding: 6px 15px;
    text-decoration: none;
    text-shadow: 0 1px 0 #b23e35;
    margin: 10px auto 30px;
}

.logout:hover {
    background-color: #eb675e;
}

.logout:active {
    position: relative;
    top: 1px;
}