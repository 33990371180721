p {
    color: black;
    margin: 0.1em;
}

pre {
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-bottom: 2em;
}

.container {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
}

.container .wrapper {
    max-width: 500px;
    border: 1px black solid;
    padding: 20px;
    position: relative;
    margin: 0.2em auto;
}

.icon-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 34px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.row *:first-child {
    min-width: 0;
}

.from {
    flex-grow: 1;
}

.subject {
    font-weight: bold;
    padding-bottom: 1em;
}

.sentAt {
    margin-left: auto;
    font-size: 1em;
}