.loginWrapper{
  background-color: #B70C3450;;
  width: 60%;
  max-width: 250px;
  height: 115px;
  margin: auto;
  padding: 35px;
  border-radius: 30px;
}

.loginWrapper form{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 115px;
  align-items: center;
}

.loginWrapper input{
  width: 100%;
}

/*ErrorMessage */
.loginWrapper p{
  color: #a10027;
  margin: 5px;
}


input{
  line-height: 2em;
}

input[type="submit"] {
  line-height: 1em;
  background-color:#B70C34;;
  border-radius:4px;
  border:1px solid #ffffff;
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-family:Arial;
  font-size:15px;
  padding:6px 15px;
  text-decoration:none;
  text-shadow:0px 1px 0px #b23e35;
}
input[type="submit"]:hover {
  background-color:#eb675e;
}

input[type="submit"]:active {
  position:relative;
  top:1px;
}